<template>
  <v-app>
    <main>
      <v-img
        class="fill-height"
        :aspect-ratio="16 / 9"
        height="100vh"
        width="100vw"
      >
        <v-container fill-height>
          <v-row justify="center" align="center">
            <v-col cols="12" md="8" lg="6" xl="4">
              <v-card color="rgb(222 222 222 / 20%)" class="py-8">
                <v-card-text class="text-center">
                  <h2
                    class="text-h5 black--text d-flex flex-column justify-center"
                  >
                    <v-img
                      :src="siteAppURL + '/images/logo.png'"
                      width="200"
                      max-width="200"
                      contain
                      class="mx-auto mb-5"
                    >
                    </v-img>
                    <span class="mt-4">管理画面</span>
                  </h2>
                  <v-form v-model="isValid">
                    <div class="mt-6">
                      <v-row justify="center">
                        <v-col cols="12" md="10">
                          <v-text-field
                            v-model="email"
                            :rules="rules.email"
                            label="メールアドレス"
                            name="email"
                            outlined
                            prepend-inner-icon="mdi-account-outline"
                            hide-details="auto"
                          ></v-text-field>

                          <v-text-field
                            v-model="password"
                            :rules="rules.password"
                            label="パスワード"
                            name="password"
                            outlined
                            prepend-inner-icon="mdi-lock-open"
                            :type="showPassword ? 'text' : 'password'"
                            :append-icon="
                              showPassword ? 'mdi-eye' : 'mdi-eye-off'
                            "
                            @click:append="showPassword = !showPassword"
                            hide-details="auto"
                            class="mt-6"
                          ></v-text-field>

                          <div class="d-flex mt-2" v-if="inValidMsg !== ''">
                            <span class="red--text">{{ inValidMsg }}</span>
                          </div>
                          <div
                            class="d-flex align-center justify-space-between mt-4"
                          >
                            <v-checkbox
                              label="ログイン情報を保存する"
                              class="custom-checkbox font-14px"
                            ></v-checkbox>
                            <router-link
                              class="font-14px"
                              :to="{ name: 'ForgotPassword' }"
                              >パスワードをお忘れですか？</router-link
                            >
                          </div>

                          <div class="mt-6">
                            <v-btn
                              block
                              class="text-capitalize"
                              large
                              :loading="loading"
                              color="primary"
                              :disabled="!isValid"
                              @click="login"
                              >ログイン</v-btn
                            >
                          </div>
                        </v-col>
                      </v-row>
                    </div>
                  </v-form>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-img>
    </main>
  </v-app>
</template>

<script>
export default {
  name: 'Login',
  data() {
    return {
      showPassword: false,
      email: null,
      password: null,
      loading: false,
      isValid: true,
      inValidMsg: '',
      siteAppURL: process.env.VUE_APP_URL,
      rules: {
        email: [
          v => !!v || 'メールアドレスが必要です。',
          v =>
            /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
            '正しいメールアドレスをご入力ください。'
        ],
        password: [
          v => !!v || 'パスワードが必要です。',
          v => (v && v.length >= 6) || 'パスワードの長さは6文字以上必要です。'
        ]
      }
    }
  },

  methods: {
    login: function() {
      this.inValidMsg = ''
      this.loading = true
      let email = this.email
      let password = this.password
      this.$store
        .dispatch('AUTH_REQUEST', { email, password })
        .then(res => {
          let result = res.data
          if (result.status == 'success') {
            this.$router.push('/quote')
          }
          this.loading = false
          if (result.status == 'failed') {
            this.inValidMsg = result.message
          }
        })
        .catch(err => {
          console.log(err)
          this.inValidMsg =
            'ログイン情報が正しくありません。もう一度お試しください。'
          this.loading = false
        })
    }
  }
}
</script>
<style src="./login.scss" lang="scss" />
